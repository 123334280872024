<template>
  <span class="agreement-record-footer d-flex justify-content-end">
    <el-button type="primary" @click="$emit('formSubmitClicked')">
      {{ $t('saveAndSubmit') }}
    </el-button>
  </span>
</template>

<script>
export default {
  name: 'AgreementRecordFooter',
};
</script>

<style scoped lang="scss">
.agreement-record-footer {
  border: 1px solid #e5e8ea;
  border-radius: 0 0 0.3em 0.3em;
  padding: 1em 1.6em;
}
</style>
