<template>
  <el-form ref="pricingForm" :model="pricingForm" :rules="pricingFormValidationRules" class="p-4">
    <h6>
      <b>{{ $t('pricingConfiguration') }}</b>
    </h6>
    <div class="row my-0 mx-0 w-100">
      <el-form-item prop="pricingMethod" :label="$t('pricingMethod')" label-position="top" class="my-0 w-100">
        <el-select v-model="pricingForm.pricingMethod" :placeholder="$t('select')" class="w-100">
          <el-option :key="pricingMethods.FIXED_PRICE" :label="$t('fixedPrice')" :value="pricingMethods.FIXED_PRICE" />
          <el-option :key="pricingMethods.PRICE_LIST" :label="$t('priceList')" :value="pricingMethods.PRICE_LIST" />
          <el-option :key="pricingMethods.PRICE_INDEX" :label="$t('priceIndex')" :value="pricingMethods.PRICE_INDEX" />
          <el-option :key="pricingMethods.PER_ORDER" :label="$t('perOrder')" :value="pricingMethods.PER_ORDER" />
        </el-select>
      </el-form-item>
    </div>
    <template v-if="pricingForm.pricingMethod === pricingMethods.FIXED_PRICE">
      <div class="row my-0 mx-0 w-100">
        <el-form-item prop="price" :label="$t('agreementPrice')" label-position="top" class="my-0 ms-4">
          <input-money v-model="pricingForm.price" />
        </el-form-item>
        <el-form-item class="my-0 d-flex align-self-end">
          <el-checkbox v-model="pricingForm.priceIncludingTax">
            {{ $t('priceIncludingTax') }}
          </el-checkbox>
        </el-form-item>
      </div>
    </template>
    <template v-if="pricingForm.pricingMethod === pricingMethods.PRICE_LIST">
      <div class="row my-0 mx-0 w-100">
        <el-form-item prop="priceListId" :label="$t('priceList')" label-position="top" class="my-0" required>
          <el-select v-model="pricingForm.priceListId" filterable class="w-100">
            <el-option
              v-for="priceList in priceLists"
              :key="priceList.id"
              :label="priceList.name"
              :value="priceList.id"
            />
          </el-select>
        </el-form-item>
      </div>
    </template>
    <template v-else-if="pricingForm.pricingMethod === pricingMethods.PRICE_INDEX">
      <div class="row my-0 mx-0 w-100">
        <el-form-item prop="priceIndexId" :label="$t('priceIndex')" label-position="top" class="my-0 ms-4">
          <el-select
            v-model="pricingForm.priceIndexId"
            filterable
            autocomplete="true"
            @change="pricingForm.priceIndexItemId = ''"
          >
            <el-option
              v-for="priceIndex in priceIndexes"
              :key="priceIndex._id"
              :value="priceIndex._id"
              :label="priceIndex.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="priceIndexItemId" :label="$t('productInIndex')" label-position="top" class="my-0">
          <el-select v-model="pricingForm.priceIndexItemId" clearable>
            <el-option v-for="item in priceIndexItems" :key="item._id" :value="item._id" :label="item.name" />
          </el-select>
        </el-form-item>
      </div>
      <div class="row my-0 mx-0 w-100">
        <el-form-item prop="quality" :label="$t('quality')" label-position="top" class="ps-4 my-0">
          <el-select v-model="pricingForm.quality" disabled> </el-select>
        </el-form-item>
        <el-form-item prop="priceIndexOffset" :label="$t('priceIndexOffset')" label-position="top" class="ps-4 my-0">
          <el-select v-model="pricingForm.priceIndexOffset">
            <el-option :key="'commissionSameDay'" :label="$t('sameDay')" :value="0" />
            <el-option :key="'commissionDayBefore'" :label="$t('dayBefore')" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item prop="commission" :label="$t('commission')" label-position="top" class="ps-4 my-0">
          <el-select v-model="pricingForm.commission">
            <el-option :key="'withCommission'" :label="$t('withCommission')" :value="true" />
            <el-option :key="'withoutCommission'" :label="$t('withoutCommission')" :value="false" />
          </el-select>
        </el-form-item>
        <el-form-item prop="priceIndexCommission" :label="$t('priceIndexCommission')" label-position="top" class="my-0">
          <input-money v-model="pricingForm.priceIndexCommission" :disabled="!pricingForm.commission" />
        </el-form-item>
      </div>
    </template>
    <div class="d-flex justify-content-end mt-5">
      <el-button type="primary" @click="submitForm">
        {{ $t('save') }}
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { PRICING_METHODS_ENUM } from '@/modules/suppliers/models/term';
import { omit, isEmpty, reject } from 'ramda';

export default {
  name: 'AgreementFormPricing',
  props: {
    initialData: {
      type: Object,
      default() {
        return {};
      },
    },
    priceLists: { type: Array, default: () => [] },
  },
  data() {
    return {
      pricingForm: {
        pricingMethod: PRICING_METHODS_ENUM.FIXED_PRICE,
        price: 0,
        priceListId: '',
        priceIndexId: '',
        priceIndexItemId: '',
        quality: '',
        priceIndexOffset: 0,
        commission: false,
        priceIndexCommission: 0,
        priceIncludingTax: false,
        type: 'pricing',
        ...this.initialData,
      },
    };
  },
  computed: {
    priceIndexes() {
      return this.$store.state.collections.priceIndexes;
    },
    priceIndexItems() {
      if (!this.pricingForm.priceIndexId) return [];
      const priceIndex = this.priceIndexes.find((pi) => pi._id === this.pricingForm.priceIndexId);
      if (!priceIndex) return [];
      return priceIndex.items.sort((itemA, itemB) => itemA.name.toString().localeCompare(itemB.name.toString(), 'he'));
    },
    pricingMethods() {
      return PRICING_METHODS_ENUM;
    },
    pricingFormValidationRules() {
      const pricingMethodRule = { required: true, message: 'Please select a pricing method', trigger: 'change' };
      let rules;

      if (this.pricingForm.pricingMethod === PRICING_METHODS_ENUM.FIXED_PRICE) {
        rules = {
          pricingMethod: pricingMethodRule,
          price: {
            type: 'number',
            required: true,
            min: 0,
            message: 'Price should be a positive number',
            trigger: 'change',
          },
        };
      } else if (this.pricingForm.pricingMethod === PRICING_METHODS_ENUM.PRICE_INDEX) {
        rules = {
          pricingMethod: pricingMethodRule,
          priceIndex: { required: true, message: 'Please select a price index', trigger: 'change' },
          priceIndexOffset: { type: 'enum', enum: [0, 1], required: true, message: 'Please select day of index' },
          commission: { type: 'boolean', defaultField: false },
          priceIndexCommission: {
            type: 'number',
            min: 0,
            message: 'Total commission should be a positive number',
            trigger: 'change',
          },
        };
      } else {
        rules = {
          pricingMethod: pricingMethodRule,
        };
      }
      return rules;
    },
  },
  methods: {
    submitForm() {
      this.$refs.pricingForm.validate((valid) => {
        if (valid) {
          this.$emit('pricingMethodFormSubmit', reject(isEmpty)(omit(['quality', 'commission'], this.pricingForm)));
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
