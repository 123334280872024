<template>
  <div class="agreement-record-header-form">
    <div class="agreement-record-header-form__col agreement-record-header-form__supplier">
      <el-form-item
        prop="supplierId"
        required
        class="agreement-record-header-form__supplier-form-item"
        size="medium"
        :label="$t('supplier')"
      >
        <el-select v-model="supplierId" filterable clearable class="w-100" data-field-name="supplierId">
          <el-option v-for="supplier in suppliers" :key="supplier._id" :label="supplier.name" :value="supplier._id" />
        </el-select>
      </el-form-item>
    </div>
    <el-divider direction="vertical" class="agreement-record-header-form__divider" />
    <div class="agreement-record-header-form__col">
      <el-form-item prop="fromDate" size="medium" :label="$t('agreementStart')">
        <input-date v-model="formData.fromDate" class="w-100" data-field-name="fromDate" />
      </el-form-item>
      <el-form-item prop="toDate" size="medium" :label="$t('agreementEnd')">
        <input-date v-model="formData.toDate" class="w-100" data-field-name="toDate" />
      </el-form-item>
    </div>
    <el-divider direction="vertical" class="agreement-record-header-form__divider" />
    <div class="agreement-record-header-form__col agreement-record-header-form__agreement">
      <el-form-item
        prop="agreementType"
        size="medium"
        class="agreement-record-header-form__agreement--type"
        :label="$t('agreementType')"
      >
        <el-select v-model="formData.agreementType" data-field-name="agreementType">
          <el-option v-for="type in agreementTypes" :key="type" :label="$t(type)" :value="type" />
        </el-select>
      </el-form-item>
      <el-form-item
        prop="agreementDate"
        size="medium"
        class="agreement-record-header-form__agreement--date"
        :label="$t('agreementDate')"
      >
        <input-date v-model="formData.agreementDate" class="w-100" data-field-name="agreementDate" />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import InputDate from '@/modules/core/components/forms/InputDate';

export default {
  name: 'AgreementRecordHeaderForm',
  components: { InputDate },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    suppliers: {
      type: Array,
      default: () => [],
    },
    agreementTypes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    supplierId: {
      get() {
        return this.formData.supplierId;
      },
      set(newSupplierId) {
        if (this.formData.terms.length) {
          this.$confirm(this.$i18n.t('agreementRecording.supplierChangeWarning'), {
            type: 'warning',
          })
            .then(() => {
              this.formData.supplierId = newSupplierId;
              this.$emit('supplierChange');
            })
            .catch(() => {});
        } else {
          this.formData.supplierId = newSupplierId;
          this.$emit('supplierChange');
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.agreement-record-header-form {
  background: white;
  border: 1px solid #e5e8ea;
  border-radius: 0.3em 0.3em 0 0;
  width: 100%;
  margin-right: 0;
  padding: 0 0.8em;
  display: flex;
  align-items: center;
  height: 6em;

  &__col {
    display: flex;
    height: 100%;
    align-items: center;
  }

  &__supplier {
    flex: 0 0 16%;
  }

  &__agreement {
    display: flex;

    &--is-agreement-checkbox {
      flex: 0 1 15%;
    }
    &--date {
      flex: 0 1 30%;
    }
    &--type {
      flex: 0 1 28%;
    }
  }

  & .el-form-item {
    margin-bottom: 0;
    width: 100%;

    &:not(:last-child) {
      margin-left: 0.5em;
    }
  }

  &__divider {
    height: 4em;
    margin: 0 1.5em !important;
  }
}
</style>
